import qs from 'qs'
import iFrameResize from 'iframe-resizer/js/iframeResizer'
import looseJson from 'loose-json'
import getHashConfig from '@/utils/getHashConfig'

const HASH_PARAMETERS_KEYS = ['country', 'place_from', 'place_to']

function removeHashParameters (config) {
  Object.keys(config).forEach((key) => {
    if (HASH_PARAMETERS_KEYS.includes(key)) {
      delete config[key]
    }
  })
}

function getConfig (element) {
  if (element && element.innerHTML) {
    const json = element.innerHTML
    const userConfig = looseJson(json.substring(json.indexOf('{'), json.lastIndexOf('}') + 1))
    const hashConfig = getHashConfig()
    const isHashPresent = !!Object.values(hashConfig).length

    if (isHashPresent) {
      removeHashParameters(userConfig)
    }

    return {
      ...userConfig,
      ...hashConfig,
      refx2s6d: window.location.href
    }
  }

  return {}
}

function getIframeElement (config, baseUrl) {
  const iframe = document.createElement('iframe')
  const style = iframe.style

  style.width = '1px'
  style.minWidth = '100%'
  style.border = 'none'
  style.display = 'block'
  style.height = '0'

  iframe.scrolling = 'no'
  iframe.src = `${baseUrl}?${qs.stringify(config)}`

  return iframe
}

function initWidget (context) {
  const element = context.querySelector('[data-kiwitaxi-reviews-element]')
  const config = getConfig(context.querySelector('[data-kiwitaxi-reviews-config]'))
  const baseUrl = element.dataset.baseUrl || 'https://widget-reviews.kiwitaxi.com/'
  const iframe = getIframeElement(config, baseUrl)

  element.innerHTML = ''
  element.appendChild(iframe)
  iFrameResize({}, iframe)
}

function initWidgetsBySelector (selector) {
  const contexts = document.querySelectorAll(selector);
  [].forEach.call(contexts, context => initWidget(context))
}

initWidgetsBySelector('[data-kiwitaxi-reviews]')
window.initKiwitaxiSearch = initWidgetsBySelector
