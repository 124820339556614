const hashParameters = {
  country: 'country',
  from: 'place_from',
  to: 'place_to'
}
const hashKeys = Object.keys(hashParameters)

export default function () {
  const hash = window.location.hash.substring(1)
  const list = hash.split('/').filter(item => item)

  return list.reduce((config, item, i) => {
    const isKey = hashKeys.includes(item)
    const nextItem = list[i + 1]
    const nextIsNotKey = !hashKeys.includes(nextItem)

    if (isKey && nextItem && nextIsNotKey) {
      const parameter = hashParameters[item]
      config[parameter] = nextItem.split('+').join(' ')
    }

    return config
  }, {})
}
